export const toReadableText = (text: string) => {
  // Check if the input text is not empty
  if (text && text.length > 0) {
    // Convert the input text to lowercase
    const allLowercase = text.toLowerCase();

    // Split the text into its components using '_' as the separator
    let textComponents = allLowercase.split("_");

    // Check if there are multiple components (snake_case)
    if (textComponents && textComponents.length > 1) {
      // Capitalize the first letter of each component and join them with a space
      for (let itemIndex = 0; itemIndex < textComponents.length; itemIndex++) {
        textComponents[itemIndex] =
          textComponents[itemIndex][0].toUpperCase() +
          textComponents[itemIndex].substring(1);
      }
      return textComponents.join(" ");
    }

    // If there's only one component (camelCase), capitalize its first letter and return
    return allLowercase[0].toUpperCase() + allLowercase.substring(1);
  }

  // If the input text is empty or undefined, return an empty string
  return text;
};
