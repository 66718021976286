export const rulesMeta = {
  conditions: [
    {
      name: "CHANGE_TYPE",
      statements: [
        {
          name: "IS",
          requires_input: true,
          field_type: "choice",
        },
        {
          name: "IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "RECORD_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "RECORD_NOT_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "EMAIL_STATUS_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "EMAIL_STATUS_NOT_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
      ],
      data_options: [
        "FILL_IN_BLANK",
        "CORRECTION",
        "CLEAN_UP",
        "INVALID_DATA",
        "STANDARDIZATION",
        "INFORMATION",
        "LINKAGE",
      ],
      category: "Cirrom Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CHANGE_INSTIGATOR",
      statements: [
        {
          name: "IS",
          requires_input: true,
          field_type: "choice",
        },
        {
          name: "IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "RECORD_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "RECORD_NOT_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "EMAIL_STATUS_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "EMAIL_STATUS_NOT_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
      ],
      data_options: [
        "Spam_Data_Cache_Refresh",
        "Phone_Location_Data_Enrichment",
        "Address_Location_Data_Enrichment",
        "Location_Linkage_Enrichment",
        "Location_Enrichment",
        "Phone_Location_Enrichment",
        "Unknown_Country_Enrichment",
        "Invalid_State_Enrichment",
        "Telephone_Standardization",
        "Telephone_Correction",
        "CRM_Data_Enrichment",
        "MX_RECORD_CHECK",
        "GENERIC_EMAIL_CHECK",
        "PERSONAL_EMAIL_CHECK",
        "BOUNCED_EMAIL_CHECK",
        "Flipped Names From Email Model",
        "Enrich Email Model",
        "Valid Email Format But Different From Email Model",
        "Name From Email Model",
        "Name From Name",
        "Name From Email",
        "Email Casing Check",
        "Suffix_From_Name",
        "NAME_STANDARDIZATION",
        "JOB_TITLE_CHECK",
        "JOB_TITLE_SPELLCHECK",
        "JOB_CHANGED_CHECK",
        "COMPANY_CHANGED_CHECK",
      ],
      category: "Cirrom Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CHANGE_FIELD",
      statements: [
        {
          name: "IS",
          requires_input: true,
          field_type: "choice",
        },
        {
          name: "IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
      ],
      data_options: [
        "address_linkage",
        "city",
        "country",
        "company",
        "first_name",
        "job_title",
        "last_name",
        "middle_initial",
        "primary_email",
        "stateorprovince",
        "telephone",
      ],
      category: "Cirrom Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "PROBABILITY",
      statements: [
        {
          name: "LESS_THAN_EQUAL",
          requires_input: true,
          field_type: "num",
        },
        {
          name: "LESS_THAN",
          requires_input: true,
          field_type: "num",
        },
        {
          name: "EQUALS",
          requires_input: true,
          field_type: "num",
        },
        {
          name: "GREATER_THAN_EQUAL",
          requires_input: true,
          field_type: "num",
        },
        {
          name: "GREATER_THAN",
          requires_input: true,
          field_type: "num",
        },
      ],
      data_options: [],
      category: "Cirrom Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "LISTS",
      statements: [
        {
          name: "RECORD_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "RECORD_NOT_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
      ],
      data_options: [],
      category: "CRM Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "COUNTRY",
      statements: [
        {
          name: "IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
      ],
      data_options: [
        "Andorra",
        "United Arab Emirates",
        "Afghanistan",
        "Antigua and Barbuda",
        "Anguilla",
        "Albania",
        "Armenia",
        "Angola",
        "Antarctica",
        "Argentina",
        "American Samoa",
        "Austria",
        "Australia",
        "Aruba",
        "Aland Islands",
        "Azerbaijan",
        "Bosnia and Herzegovina",
        "Barbados",
        "Bangladesh",
        "Belgium",
        "Burkina Faso",
        "Bulgaria",
        "Bahrain",
        "Burundi",
        "Benin",
        "Saint Barthelemy",
        "Bermuda",
        "Brunei",
        "Bolivia",
        "Bonaire, Saint Eustatius and Saba ",
        "Brazil",
        "Bahamas",
        "Bhutan",
        "Bouvet Island",
        "Botswana",
        "Belarus",
        "Belize",
        "Canada",
        "Cocos Islands",
        "Democratic Republic of the Congo",
        "Central African Republic",
        "Republic of the Congo",
        "Switzerland",
        "Ivory Coast",
        "Cook Islands",
        "Chile",
        "Cameroon",
        "China",
        "Colombia",
        "Costa Rica",
        "Cuba",
        "Cabo Verde",
        "Curacao",
        "Christmas Island",
        "Cyprus",
        "Czechia",
        "Germany",
        "Djibouti",
        "Denmark",
        "Dominica",
        "Dominican Republic",
        "Algeria",
        "Ecuador",
        "Estonia",
        "Egypt",
        "Western Sahara",
        "Eritrea",
        "Spain",
        "Ethiopia",
        "Finland",
        "Fiji",
        "Falkland Islands",
        "Micronesia",
        "Faroe Islands",
        "France",
        "Gabon",
        "United Kingdom",
        "Grenada",
        "Georgia",
        "French Guiana",
        "Guernsey",
        "Ghana",
        "Gibraltar",
        "Greenland",
        "Gambia",
        "Guinea",
        "Guadeloupe",
        "Equatorial Guinea",
        "Greece",
        "South Georgia and the South Sandwich Islands",
        "Guatemala",
        "Guam",
        "Guinea-Bissau",
        "Guyana",
        "Hong Kong",
        "Heard Island and McDonald Islands",
        "Honduras",
        "Croatia",
        "Haiti",
        "Hungary",
        "Indonesia",
        "Ireland",
        "Israel",
        "Isle of Man",
        "India",
        "British Indian Ocean Territory",
        "Iraq",
        "Iran",
        "Iceland",
        "Italy",
        "Jersey",
        "Jamaica",
        "Jordan",
        "Japan",
        "Kenya",
        "Kyrgyzstan",
        "Cambodia",
        "Kiribati",
        "Comoros",
        "Saint Kitts and Nevis",
        "North Korea",
        "South Korea",
        "Kosovo",
        "Kuwait",
        "Cayman Islands",
        "Kazakhstan",
        "Laos",
        "Lebanon",
        "Saint Lucia",
        "Liechtenstein",
        "Sri Lanka",
        "Liberia",
        "Lesotho",
        "Lithuania",
        "Luxembourg",
        "Latvia",
        "Libya",
        "Morocco",
        "Monaco",
        "Moldova",
        "Montenegro",
        "Saint Martin",
        "Madagascar",
        "Marshall Islands",
        "North Macedonia",
        "Mali",
        "Myanmar",
        "Mongolia",
        "Macao",
        "Northern Mariana Islands",
        "Martinique",
        "Mauritania",
        "Montserrat",
        "Malta",
        "Mauritius",
        "Maldives",
        "Malawi",
        "Mexico",
        "Malaysia",
        "Mozambique",
        "Namibia",
        "New Caledonia",
        "Niger",
        "Norfolk Island",
        "Nigeria",
        "Nicaragua",
        "Netherlands",
        "Norway",
        "Nepal",
        "Nauru",
        "Niue",
        "New Zealand",
        "Oman",
        "Panama",
        "Peru",
        "French Polynesia",
        "Papua New Guinea",
        "Philippines",
        "Pakistan",
        "Poland",
        "Saint Pierre and Miquelon",
        "Pitcairn",
        "Puerto Rico",
        "Palestinian Territory",
        "Portugal",
        "Palau",
        "Paraguay",
        "Qatar",
        "Reunion",
        "Romania",
        "Serbia",
        "Russia",
        "Rwanda",
        "Saudi Arabia",
        "Solomon Islands",
        "Seychelles",
        "Sudan",
        "South Sudan",
        "Sweden",
        "Singapore",
        "Saint Helena",
        "Slovenia",
        "Svalbard and Jan Mayen",
        "Slovakia",
        "Sierra Leone",
        "San Marino",
        "Senegal",
        "Somalia",
        "Suriname",
        "Sao Tome and Principe",
        "El Salvador",
        "Sint Maarten",
        "Syria",
        "Eswatini",
        "Turks and Caicos Islands",
        "Chad",
        "French Southern Territories",
        "Togo",
        "Thailand",
        "Tajikistan",
        "Tokelau",
        "Timor Leste",
        "Turkmenistan",
        "Tunisia",
        "Tonga",
        "Turkey",
        "Trinidad and Tobago",
        "Tuvalu",
        "Taiwan",
        "Tanzania",
        "Ukraine",
        "Uganda",
        "United States Minor Outlying Islands",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vatican",
        "Saint Vincent and the Grenadines",
        "Venezuela",
        "British Virgin Islands",
        "U.S. Virgin Islands",
        "Vietnam",
        "Vanuatu",
        "Wallis and Futuna",
        "Samoa",
        "Yemen",
        "Mayotte",
        "South Africa",
        "Zambia",
        "Zimbabwe",
        "Serbia and Montenegro",
        "Netherlands Antilles",
      ],
      category: "Cirrom Location",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "STATE",
      statements: [
        {
          name: "IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
      ],
      data_options: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington, D.C.",
        "Washington State",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      category: "Cirrom Location",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CITY",
      statements: [
        {
          name: "IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "Cirrom Location",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CHANGE_PREVIOUS_VALUE",
      statements: [
        {
          name: "CONTAINS",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "DOES_NOT_CONTAIN",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "BEGINS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "ENDS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_UPPERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_LOWERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "GREATER_THAN_LENGTH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "TEXT_EQUALS",
          requires_input: true,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "Cirrom Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CHANGE_NEW_VALUE",
      statements: [
        {
          name: "CONTAINS",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "DOES_NOT_CONTAIN",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "BEGINS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "ENDS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_UPPERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_LOWERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "GREATER_THAN_LENGTH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "TEXT_EQUALS",
          requires_input: true,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "Cirrom Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "TELEPHONE",
      statements: [
        {
          name: "CONTAINS",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "DOES_NOT_CONTAIN",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "BEGINS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "ENDS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_UPPERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_LOWERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "GREATER_THAN_LENGTH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "TEXT_EQUALS",
          requires_input: true,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "CRM Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CHANGE_PREVIOUS_NEW_VALUE_COMPARE",
      statements: [
        {
          name: "NEW_EQUALS_PREVIOUS_CASE_INSENSITIVE",
          requires_input: false,
          field_type: null,
        },
        {
          name: "SIMILARITY_PERCENTAGE_GREATER_OR_EQUAL_THAN",
          requires_input: true,
          field_type: null,
        },
        {
          name: "NEW_EQUALS_PREVIOUS_WITH_TRIM_SPACES",
          requires_input: false,
          field_type: null,
        },
      ],
      data_options: [],
      category: "Cirrom Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "EMAIL_STATUS",
      statements: [
        {
          name: "EMAIL_STATUS_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "EMAIL_STATUS_NOT_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
      ],
      data_options: ["Delivered", "Hard Bounce", "Activity"],
      category: "Email Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CONTACT_COUNTRY",
      statements: [
        {
          name: "CONTAINS",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "DOES_NOT_CONTAIN",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "BEGINS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "ENDS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_UPPERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_LOWERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "GREATER_THAN_LENGTH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "TEXT_EQUALS",
          requires_input: true,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "CRM Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CONTACT_STATE",
      statements: [
        {
          name: "CONTAINS",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "DOES_NOT_CONTAIN",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "BEGINS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "ENDS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_UPPERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_LOWERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "GREATER_THAN_LENGTH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "TEXT_EQUALS",
          requires_input: true,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "CRM Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CONTACT_CITY",
      statements: [
        {
          name: "CONTAINS",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "DOES_NOT_CONTAIN",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "BEGINS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "ENDS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_UPPERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_LOWERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "GREATER_THAN_LENGTH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "TEXT_EQUALS",
          requires_input: true,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "CRM Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "TEXTUAL_ACCOUNT",
      statements: [
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "CRM Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "ACCOUNT_COUNTRY",
      statements: [
        {
          name: "CONTAINS",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "DOES_NOT_CONTAIN",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "BEGINS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "ENDS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_UPPERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_LOWERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "GREATER_THAN_LENGTH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "TEXT_EQUALS",
          requires_input: true,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "CRM Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "ACCOUNT_STATE",
      statements: [
        {
          name: "CONTAINS",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "DOES_NOT_CONTAIN",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "BEGINS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "ENDS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_UPPERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_LOWERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "GREATER_THAN_LENGTH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "TEXT_EQUALS",
          requires_input: true,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "CRM Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "ACCOUNT_CITY",
      statements: [
        {
          name: "CONTAINS",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "DOES_NOT_CONTAIN",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "BEGINS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "ENDS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_UPPERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_LOWERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "GREATER_THAN_LENGTH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "TEXT_EQUALS",
          requires_input: true,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "CRM Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CONTACT_CITY_VS_CIRROM_CITY",
      statements: [
        {
          name: "DISTANCE_LESS_THAN",
          requires_input: true,
          field_type: "num",
        },
        {
          name: "DISTANCE_GREATER_THAN",
          requires_input: true,
          field_type: null,
        },
      ],
      data_options: [],
      category: "CRM Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "NEW_VALUE_COMPARE_TO_CRM",
      statements: [
        {
          name: "NEW_EQUALS_CRM_ACCOUNT_STATE",
          requires_input: false,
          field_type: null,
        },
        {
          name: "NEW_EQUALS_CRM_ACCOUNT_CITY",
          requires_input: false,
          field_type: null,
        },
      ],
      data_options: [],
      category: "Cirrom Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "LINKAGE",
      statements: [
        {
          name: "CONTACT_HAS_LINKED_ACCOUNT",
          requires_input: false,
          field_type: null,
        },
        {
          name: "CONTACT_HAS_NO_LINKED_ACCOUNT",
          requires_input: false,
          field_type: null,
        },
        {
          name: "CONTACT_HAS_ACCOUNT_LINKED_ADDRESS",
          requires_input: false,
          field_type: null,
        },
        {
          name: "CONTACT_HAS_ACCOUNT_LINKED_PHONE",
          requires_input: false,
          field_type: null,
        },
        {
          name: "CONTACT_HAS_OWN_ADDRESS",
          requires_input: false,
          field_type: null,
        },
        {
          name: "CONTACT_HAS_OWN_PHONE",
          requires_input: false,
          field_type: null,
        },
      ],
      data_options: [],
      category: "Cirrom Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CHANGE_ENTITY",
      statements: [
        {
          name: "IS",
          requires_input: true,
          field_type: "choice",
        },
        {
          name: "IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "RECORD_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "RECORD_NOT_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "EMAIL_STATUS_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "EMAIL_STATUS_NOT_IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
      ],
      data_options: ["CONTACT", "ACCOUNT"],
      category: "Cirrom Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CONTACT_EMPLOYMENT",
      statements: [
        {
          name: "IS",
          requires_input: true,
          field_type: "choice",
        },
      ],
      data_options: [],
      category: "CRM Attributes",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CHANGE_CRM_STATE",
      statements: [
        {
          name: "IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS",
          requires_input: true,
          field_type: "choice",
        },
      ],
      data_options: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington, D.C.",
        "Washington State",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Newfoundland and Labrador",
        "Nova Scotia",
        "Ontario",
        "Prince Edward Island",
        "Quebec",
        "Saskatchewan",
      ],
      category: "Change Address",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CHANGE_CIRROM_STATE",
      statements: [
        {
          name: "IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS",
          requires_input: true,
          field_type: "choice",
        },
      ],
      data_options: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington, D.C.",
        "Washington State",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Newfoundland and Labrador",
        "Nova Scotia",
        "Ontario",
        "Prince Edward Island",
        "Quebec",
        "Saskatchewan",
      ],
      category: "Change Address",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CHANGE_CRM_CITY",
      statements: [
        {
          name: "CONTAINS",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "DOES_NOT_CONTAIN",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "BEGINS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "ENDS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_UPPERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_LOWERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "GREATER_THAN_LENGTH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "TEXT_EQUALS",
          requires_input: true,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "Change Address",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CHANGE_CIRROM_CITY",
      statements: [
        {
          name: "CONTAINS",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "DOES_NOT_CONTAIN",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "BEGINS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "ENDS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_UPPERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_LOWERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "GREATER_THAN_LENGTH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "TEXT_EQUALS",
          requires_input: true,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "Change Address",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CHANGE_CRM_COUNTRY",
      statements: [
        {
          name: "CONTAINS",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "DOES_NOT_CONTAIN",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "BEGINS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "ENDS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_UPPERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_LOWERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "GREATER_THAN_LENGTH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "TEXT_EQUALS",
          requires_input: true,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "Change Address",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "CHANGE_CIRROM_COUNTRY",
      statements: [
        {
          name: "IN",
          requires_input: true,
          field_type: "multiple_choice",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS",
          requires_input: true,
          field_type: "choice",
        },
      ],
      data_options: [
        "AD",
        "AE",
        "AF",
        "AG",
        "AI",
        "AL",
        "AM",
        "AO",
        "AQ",
        "AR",
        "AS",
        "AT",
        "AU",
        "AW",
        "AX",
        "AZ",
        "BA",
        "BB",
        "BD",
        "BE",
        "BF",
        "BG",
        "BH",
        "BI",
        "BJ",
        "BL",
        "BM",
        "BN",
        "BO",
        "BQ",
        "BR",
        "BS",
        "BT",
        "BV",
        "BW",
        "BY",
        "BZ",
        "CA",
        "CC",
        "CD",
        "CF",
        "CG",
        "CH",
        "CI",
        "CK",
        "CL",
        "CM",
        "CN",
        "CO",
        "CR",
        "CU",
        "CV",
        "CW",
        "CX",
        "CY",
        "CZ",
        "DE",
        "DJ",
        "DK",
        "DM",
        "DO",
        "DZ",
        "EC",
        "EE",
        "EG",
        "EH",
        "ER",
        "ES",
        "ET",
        "FI",
        "FJ",
        "FK",
        "FM",
        "FO",
        "FR",
        "GA",
        "GB",
        "GD",
        "GE",
        "GF",
        "GG",
        "GH",
        "GI",
        "GL",
        "GM",
        "GN",
        "GP",
        "GQ",
        "GR",
        "GS",
        "GT",
        "GU",
        "GW",
        "GY",
        "HK",
        "HM",
        "HN",
        "HR",
        "HT",
        "HU",
        "ID",
        "IE",
        "IL",
        "IM",
        "IN",
        "IO",
        "IQ",
        "IR",
        "IS",
        "IT",
        "JE",
        "JM",
        "JO",
        "JP",
        "KE",
        "KG",
        "KH",
        "KI",
        "KM",
        "KN",
        "KP",
        "KR",
        "XK",
        "KW",
        "KY",
        "KZ",
        "LA",
        "LB",
        "LC",
        "LI",
        "LK",
        "LR",
        "LS",
        "LT",
        "LU",
        "LV",
        "LY",
        "MA",
        "MC",
        "MD",
        "ME",
        "MF",
        "MG",
        "MH",
        "MK",
        "ML",
        "MM",
        "MN",
        "MO",
        "MP",
        "MQ",
        "MR",
        "MS",
        "MT",
        "MU",
        "MV",
        "MW",
        "MX",
        "MY",
        "MZ",
        "NA",
        "NC",
        "NE",
        "NF",
        "NG",
        "NI",
        "NL",
        "NO",
        "NP",
        "NR",
        "NU",
        "NZ",
        "OM",
        "PA",
        "PE",
        "PF",
        "PG",
        "PH",
        "PK",
        "PL",
        "PM",
        "PN",
        "PR",
        "PS",
        "PT",
        "PW",
        "PY",
        "QA",
        "RE",
        "RO",
        "RS",
        "RU",
        "RW",
        "SA",
        "SB",
        "SC",
        "SD",
        "SS",
        "SE",
        "SG",
        "SH",
        "SI",
        "SJ",
        "SK",
        "SL",
        "SM",
        "SN",
        "SO",
        "SR",
        "ST",
        "SV",
        "SX",
        "SY",
        "SZ",
        "TC",
        "TD",
        "TF",
        "TG",
        "TH",
        "TJ",
        "TK",
        "TL",
        "TM",
        "TN",
        "TO",
        "TR",
        "TT",
        "TV",
        "TW",
        "TZ",
        "UA",
        "UG",
        "UM",
        "US",
        "UY",
        "UZ",
        "VA",
        "VC",
        "VE",
        "VG",
        "VI",
        "VN",
        "VU",
        "WF",
        "WS",
        "YE",
        "YT",
        "ZA",
        "ZM",
        "ZW",
        "CS",
        "AN",
      ],
      category: "Change Address",
      deprecated: false,
      condition_entities: [],
    },
    {
      name: "PRIMARY_EMAIL",
      statements: [
        {
          name: "CONTAINS",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "DOES_NOT_CONTAIN",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "BEGINS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "ENDS_WITH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "IS_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_NOT_EMPTY",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_UPPERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "IS_ALL_LOWERCASE",
          requires_input: false,
          field_type: "str",
        },
        {
          name: "GREATER_THAN_LENGTH",
          requires_input: true,
          field_type: "str",
        },
        {
          name: "TEXT_EQUALS",
          requires_input: true,
          field_type: "str",
        },
      ],
      data_options: [],
      category: "CRM Attributes",
      deprecated: false,
      condition_entities: [],
    },
  ],
  actions: [
    {
      action_type: "AUTO_APPROVE",
      subjects: [],
      action_value: "",
      requires_input: false,
    },
    {
      action_type: "AUTO_REJECT",
      subjects: [],
      action_value: "",
      requires_input: false,
    },
    {
      action_type: "SEND_TO_MANUAL_REVIEW",
      subjects: [],
      action_value: "",
      requires_input: false,
    },
    {
      action_type: "ADD_TO_LIST",
      subjects: [],
      action_value: "",
      requires_input: true,
    },
  ],
};
