import { CirromClient } from "../api/releases";
import React, { useEffect, useState } from "react";
import { getDataAlertsForClient, sendDataAlertEmail } from "../api/reports";
import { fetchAuthSession } from "@aws-amplify/auth";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import LinearProgress from "@mui/material/LinearProgress";
import { AgGridReact } from "ag-grid-react";
import Button from "@mui/material/Button";

const EmailButtonRenderer = (props: any) => {
  const sendEmail = async () => {
    try {
      fetchAuthSession().then((credentials) => {
        sendDataAlertEmail(credentials.tokens, props.data.id).then(
          (response) => {
            //@ts-ignore
            console.log(response);
          }
        );
      });

      // Handle success (you might want to add a toast notification here)
    } catch (error) {
      // Handle error (you might want to add a toast notification here)
      console.error("Error sending email:", error);
    }
  };

  return (
    <Button
      variant="contained"
      size="small"
      onClick={sendEmail}
      style={{ marginTop: "4px" }}
    >
      Send Email
    </Button>
  );
};

const gridColumns = [
  {
    field: "id",
    headerName: "ID",
    resizable: true,
  },
  {
    field: "data_alert_id",
    headerName: "Data Alert ID",
    resizable: true,
  },
  {
    field: "data_alert_name",
    headerName: "Data Alert Name",
    resizable: true,
  },
  {
    field: "description",
    headerName: "Description",
    resizable: true,
  },
  {
    field: "recipients",
    headerName: "Recipients",
    resizable: true,
  },
  {
    headerName: "Send Email",
    field: "send_email",
    resizable: true,
    cellRenderer: EmailButtonRenderer,
    width: 150,
    suppressMenu: true,
    sortable: false,
    filter: false,
  },
];

export function DataAlerts(props: { clients: CirromClient[] }) {
  const [clientId, setClientId] = useState<number>();

  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [alertResults, setAlertResults] = useState([]);

  useEffect(() => {
    if (clientId) {
      setIsFetchingData(true);
      fetchAuthSession().then((credentials) => {
        getDataAlertsForClient(credentials.tokens, clientId).then(
          (response) => {
            setIsFetchingData(false);
            //@ts-ignore
            setAlertResults(response);
          }
        );
      });
    }
  }, [clientId]);

  return (
    <Card>
      <CardHeader
        title={"Data Alerts"}
        titleTypographyProps={{ variant: "h3" }}
      />
      <CardContent style={{ height: "1350px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: "5px", alignItems: "baseline" }}>
            <TextField
              margin={"dense"}
              size={"small"}
              variant={"standard"}
              label={"Client Id"}
              onChange={(event) => {
                setClientId(parseInt(event.target.value));
              }}
              select
              style={{ width: "250px" }}
            >
              {props.clients
                .sort((first, second) => {
                  if (first.instance_name < second.instance_name) {
                    return -1;
                  }
                  if (first.instance_name > second.instance_name) {
                    return 1;
                  }
                  return 0;
                })
                .map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.instance_name}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </div>

        {isFetchingData && <LinearProgress variant={"indeterminate"} />}
        <div
          style={{ height: "550px", display: "flex" }}
          className="ag-theme-alpine"
        >
          <div style={{ height: "90%", width: "100%" }}>
            <AgGridReact rowData={alertResults} columnDefs={gridColumns} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
