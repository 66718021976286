import { AuthTokens } from "@aws-amplify/auth";
import axios from "axios";
import { rulesMeta } from "./rulesmeta";
export interface Rule {
  id?: number;
  rule_group_id?: number;
  active: boolean;
  description: string;
  verb: "ANY" | "ALL";
  pre_conditions?: Condition[];
  conditions: Condition[];
  actions: Action[];
  icon_code?: any;
  rule_group_type?: string;
}

export interface RuleGroup {
  id?: number;
  rule_type: string;
  rules: Rule[];
  name: string;
  batch_group_id?: number;
}

export interface Condition {
  subject: string;
  statement: string;
  expectation: any;
  id?: number;
}

export interface Action {
  action_type: string;
  action_value: string;
  subjects?: any[];
  requires_input?: boolean;
  id?: number;
}

export interface DataRuleMeta {
  preconditions?: any[];
  conditions: any[];
  actions: Action[];
}

export enum RuleType {
  FILE_UPLOAD,
  COMPLIANCE,
  CHANGES,
  CRM,
  DUPLICATES = 4,
}

export interface RuleGroupDescription {
  rule_group_id: number;
  rule_group_type: string;
}

export const getDataRulesMeta = async (
  ruleGroupId: number,
  ruleType: RuleType
): Promise<DataRuleMeta> => {
  return rulesMeta;
};

export const getRulesForClient = async (
  credentials: AuthTokens | undefined,
  clientId: number
): Promise<any[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/get_rules_for_client`,
    { client_id: clientId },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};
