import React, { useEffect, useState } from "react";
import { theme } from "./theme/theme";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
  AppBar,
  Button,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { CirromStyles } from "./components/CirromStyles";
import { Releases } from "./components/Releases";
import AWS from "aws-sdk";
import { SnackbarProvider } from "notistack";
import { Companies } from "./components/Companies";
import { Reports } from "./components/Reports";
import "./App.css";
import { Dashboards } from "./components/Dashboards";
import { CompaniesMerge } from "./components/CompaniesMerge";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession, signOut } from "@aws-amplify/auth";
import { CirromClient, getAvailableClients } from "./api/releases";
import { DataAlerts } from "./components/DataAlerts";
import {
  Assessment,
  Business,
  ChevronLeft,
  ChevronRight,
  Dashboard,
  Merge,
  Notifications,
  Send,
  Rule,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { RuleHub } from "./components/RuleHub";

const drawerWidth = 220;

const collapsedDrawerWidth = 75;

function App() {
  const [selectedView, setSelectedView] = useState<string>("1");
  const [clients, setClients] = useState<CirromClient[]>([]);
  const [isDrawerCollapsed, setIsDrawerCollapsed] = useState(false);

  useEffect(() => {
    let isMounted = true;
    AWS.config.update({ region: "us-east-2" });

    fetchAuthSession().then((credentials) => {
      getAvailableClients(credentials.tokens).then((response) => {
        if (isMounted) {
          setClients(response);
        }
      });
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const sidebarItems = [
    { label: "Reports", value: "1", icon: <Assessment /> },
    { label: "Releases", value: "2", icon: <Send /> },
    { label: "Company Map", value: "3", icon: <Business /> },
    { label: "Dashboards", value: "4", icon: <Dashboard /> },
    { label: "Companies Merge", value: "5", icon: <Merge /> },
    { label: "Data Alerts", value: "6", icon: <Notifications /> },
    { label: "Rule Hub", value: "7", icon: <Rule /> },
  ];

  const toggleDrawer = () => {
    setIsDrawerCollapsed(!isDrawerCollapsed);
  };

  return (
    <StyledEngineProvider injectFirst>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={theme}>
          <CirromStyles />
          <Box sx={{ display: "flex" }}>
            <AppBar
              position="fixed"
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <Toolbar variant="dense">
                <img
                  alt="LogoSm"
                  src="/Cirrom-C.webp"
                  style={{ height: "50px" }}
                />
                <Box sx={{ flexGrow: 1 }} />
                <Button onClick={() => signOut()}>Logout</Button>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              sx={{
                width: isDrawerCollapsed ? collapsedDrawerWidth : drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: isDrawerCollapsed ? collapsedDrawerWidth : drawerWidth,
                  boxSizing: "border-box",
                  transition: "width 0.2s",
                },
              }}
            >
              <Box
                sx={{
                  overflow: "auto",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Toolbar />
                <List>
                  {sidebarItems.map((item) => (
                    <ListItem
                      button
                      key={item.value}
                      onClick={() => setSelectedView(item.value)}
                    >
                      <ListItemIcon style={{ color: "black" }}>
                        {item.icon}
                      </ListItemIcon>
                      {!isDrawerCollapsed && (
                        <ListItemText primary={item.label} />
                      )}
                    </ListItem>
                  ))}
                </List>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  onClick={toggleDrawer}
                  sx={{ alignSelf: "flex-end", mb: 2, mr: 1 }}
                >
                  {isDrawerCollapsed ? <ChevronRight /> : <ChevronLeft />}
                </IconButton>
              </Box>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
              {selectedView === "1" && <Reports />}
              {selectedView === "2" && <Releases />}
              {selectedView === "3" && <Companies />}
              {selectedView === "4" && <Dashboards clients={clients} />}
              {selectedView === "5" && <CompaniesMerge />}
              {selectedView === "6" && <DataAlerts clients={clients} />}
              {selectedView === "7" && <RuleHub clients={clients} />}
            </Box>
          </Box>
        </ThemeProvider>
      </SnackbarProvider>
    </StyledEngineProvider>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
