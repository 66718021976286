import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  MenuItem,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { CirromClient } from "../api/releases";
import { fetchAuthSession } from "@aws-amplify/auth";
import { getRulesForClient } from "../api/rules";
import { NewRuleModal } from "./rules/RuleViewer";
import LinearProgress from "@mui/material/LinearProgress";

interface Rule {
  category: string;
  description: string;
  probability: number;
  initialChanges: number;
  totalChanges: number;
  enabled: boolean;
}

interface RuleHubProps {
  clients: CirromClient[];
}

export function RuleHub({ clients }: RuleHubProps) {
  const [clientId, setClientId] = useState<number>();
  const [rules, setRules] = useState<Rule[]>([]);
  const [selectedRule, setSelectedRule] = useState<Rule>();
  const [isFetchingInProgress, setIsFetchingInProgress] = useState(false);

  const [isRuleModalOpen, setIsRuleModalOpen] = useState<boolean>(false);

  const columns = [
    { field: "category", headerName: "Category", sortable: true, filter: true },
    {
      field: "description",
      headerName: "Rule Description",
      sortable: true,
      filter: true,
    },
    {
      field: "probability",
      headerName: "Probability",
      sortable: true,
      filter: true,
    },
    {
      field: "initialChanges",
      headerName: "Initial Changes",
      sortable: true,
      filter: true,
    },
    {
      field: "totalChanges",
      headerName: "Total Changes",
      sortable: true,
      filter: true,
    },
    {
      field: "active",
      headerName: "Active",
      sortable: true,
      filter: true,
      cellRenderer: "agCheckboxCellRenderer",
      cellRendererParams: {
        checkbox: true,
      },
      editable: true,
    },
  ];

  useEffect(() => {
    if (clientId) {
      setIsFetchingInProgress(true);
      fetchAuthSession().then((credentials) => {
        getRulesForClient(credentials.tokens, clientId).then((response) => {
          const formattedRules = response.map((rule: any) => {
            const changeFields = rule.conditions
              .filter((condition: any) => condition.subject === "CHANGE_FIELD")
              .map((condition: any) => condition.expectation)
              .join(",");

            const probability =
              rule.conditions.find(
                (condition: any) => condition.subject === "PROBABILITY"
              )?.expectation || "";

            return {
              ...rule,
              category: changeFields,
              probability: probability,
            };
          });
          //@ts-ignore
          setRules(formattedRules);
          setIsFetchingInProgress(false);
        });
      });
    }
  }, [clientId]);

  return (
    <Card>
      <CardHeader title="Rule Hub" titleTypographyProps={{ variant: "h3" }} />
      <CardContent>
        <TextField
          margin={"dense"}
          size={"small"}
          variant={"standard"}
          label={"Client Id"}
          onChange={(event) => {
            setClientId(parseInt(event.target.value));
          }}
          select
          style={{ width: "250px" }}
        >
          {clients
            .sort((first, second) => {
              if (first.instance_name < second.instance_name) {
                return -1;
              }
              if (first.instance_name > second.instance_name) {
                return 1;
              }
              return 0;
            })
            .map((client) => (
              <MenuItem key={client.id} value={client.id}>
                {client.instance_name}
              </MenuItem>
            ))}
        </TextField>
        {isFetchingInProgress && <LinearProgress variant={"indeterminate"} />}
        <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
          <AgGridReact
            rowData={rules}
            columnDefs={columns}
            defaultColDef={{
              flex: 1,
              minWidth: 100,
              resizable: true,
            }}
            onRowDoubleClicked={(rowEv) => {
              // @ts-ignore
              setIsRuleModalOpen(true);
              setSelectedRule(rowEv.node.data);
            }}
          />
        </div>
      </CardContent>
      {selectedRule && (
        <NewRuleModal
          isOpen={isRuleModalOpen}
          onClose={() => setIsRuleModalOpen(false)}
          onRuleSaved={(_ruleDetails) => {}}
          ruleGroupId={1}
          //@ts-ignore
          selectedRule={selectedRule}
        />
      )}
    </Card>
  );
}
